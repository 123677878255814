import React, { memo } from 'react';
import { Checkbox } from '@myob/myob-widgets';
import './TermsOfUse.scss';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { LEGAL_LINKS, SOLO_PRODUCT_IDS, SUBSCRIPTION_TYPE } from '../../constants';

type TermsOfUseProps = {
  isTermsCheck: boolean;
  termsErrorMessage: string;
  setTerms: () => void;
  sendPromotionsCheck:boolean;
  setSendPromotions: () => void;
}

const getTermsOfUseUrl = (productId: string, region: string): string => {
  const links = region.toUpperCase() === 'NZ' ? LEGAL_LINKS.NZ : LEGAL_LINKS.AU;
  if (SOLO_PRODUCT_IDS.includes(productId)) {
    return links.SOLO_TERMS_OF_USE;
  }
  return links.MYOB_BUSINESS_TERMS_OF_USE;
};

const getPrivacyPolicyUrl = (region: string): string => (region.toUpperCase() === 'NZ' ?
  LEGAL_LINKS.NZ.PRIVACY_STATEMENT :
  LEGAL_LINKS.AU.PRIVACY_STATEMENT
);

const offerTermsAndConditionsLink = (href: string) =>
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={href}
  >
    {' '}Offer Terms and Conditions{' '}
  </a>;

const getTermsAndConditions = (
  productId: string,
  region: string,
  promoCode?: string,
  promotionTermsLink?: string
) =>
  <div className={'terms-of-use'}>
    <span data-testid={'t&c'}>
      I&apos;ve read and agreed to the{' '}
      <a target='_blank' rel='noopener noreferrer' href={getTermsOfUseUrl(productId, region)}>Terms of Use</a>
      {promoCode && promotionTermsLink ? <>, the {offerTermsAndConditionsLink(promotionTermsLink)} and our</> : ' and '}
      <a target='_blank' rel='noopener noreferrer' href={getPrivacyPolicyUrl(region)}>{' '}Privacy Policy</a>.
    </span>
  </div>;

const TermsOfUse: React.FC<TermsOfUseProps> = ({
  isTermsCheck,
  termsErrorMessage,
  setTerms,
  sendPromotionsCheck,
  setSendPromotions
}) => {
  const {
    region,
    productId,
    promoCode,
    promotionTermsLink,
    subscriptionType
  } = useSessionContext();
  if (subscriptionType === SUBSCRIPTION_TYPE.TRIAL) {
    return null;
  }
  return (
    <div>
      <Checkbox
        className={'terms-of-use-checkbox'}
        data-testid='terms-of-use'
        name='terms-of-use'
        label={getTermsAndConditions(productId, region, promoCode, promotionTermsLink)}
        onChange={() => setTerms()}
        checked={isTermsCheck}
        errorMessage={termsErrorMessage}
      />

      <Checkbox
        className={'send-promotion-checkbox'}
        data-testid='send-promotion'
        name='send-promotion'
        label={<div className={'do-not-send-promotion'}>
          <span>
            Keep me in the loop with the latest news and special offers.
          </span>
        </div>}
        onChange={() => setSendPromotions()}
        checked={sendPromotionsCheck}
      />
    </div>
  );
};
export default memo(TermsOfUse);
