import { useQuery } from 'react-query';
import axios from 'helpers/axios/axios';
import config from 'Config';
import { ProductData, UseGetProductDataType } from '../useGetProduct/types';
import { useAuth0 } from '../Auth0/Auth0';

interface UseGetProductDataProps {
  productId?: string
  promoCode?: string,
  activationCode?: string,
  paymentSchedule?: string
}

export const useGetProductData =
  ({
    productId,
    promoCode,
    activationCode,
    paymentSchedule
  }: UseGetProductDataProps): UseGetProductDataType => {
    const { isLoading: isAuth0Loading } = useAuth0();
    const searchParams = new URLSearchParams({
      ...(productId && { productId }),
      ...(promoCode && { promoCode }),
      ...(activationCode && { activationCode }),
      ...(paymentSchedule && { paymentSchedule })
    });
    const presentmentUrl = `${config.API_PRODUCT_PRICE_ENDPOINT}?${searchParams.toString()}`;
    const {
      isLoading: isProductDataLoading, isError, data, isSuccess
    } = useQuery(
      ['productData', activationCode || promoCode, activationCode || productId, paymentSchedule],
      () => axios.get(presentmentUrl),
      {
        enabled:
        !isAuth0Loading && (Boolean(productId) || Boolean(activationCode)),
        retry: false,
        staleTime: 1000 * 60 * 5 // 5 minutes
      }
    );

    const isLoading = isProductDataLoading || isAuth0Loading;
    const productData = data?.data || {} as ProductData;
    return {
      productData, isLoading, isError, isSuccess
    };
  };

