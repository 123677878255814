import { useLocation } from 'react-router-dom';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import {
  FEELIX_THEMES,
  FLOW_STEP_MAPPING,
  FLOW_STEP_PATHS,
  FLOW_STEPS
} from '../../constants';

type GetFlowType = {
  pathname: string;
  subscriptionType: string;
  emailChecked: boolean;
  theme: string;
};
export const getFlowStep = (
  {
    pathname,
    subscriptionType,
    emailChecked,
    theme
  }: GetFlowType
) => {
  const lastToken = pathname.split('/')[3] || '';
  if (!lastToken) return emailChecked ? 0 : -1;
  const flowStep = FLOW_STEP_PATHS[lastToken];
  const flowStepNumber = FLOW_STEP_MAPPING[subscriptionType]?.findIndex((step) => step === flowStep);
  if (isNaN(flowStepNumber) || flowStepNumber === -1) return -1;

  // conditional return for solo theme
  if (theme === FEELIX_THEMES.SOLO && [FLOW_STEPS.PAYMENT, FLOW_STEPS.CONFIRMATION].includes(flowStep)) {
    return flowStepNumber + 1 > FLOW_STEP_MAPPING[subscriptionType].length - 1 ? -1 : flowStepNumber + 1;
  }

  return flowStepNumber;
};

export const useFlowStep = () => {
  const { subscriptionType, emailChecked, theme } = useSessionContext();
  const { pathname } = useLocation();
  return getFlowStep({
    pathname, subscriptionType, emailChecked, theme
  });
};
