import { Dispatch, useMemo, useReducer } from 'react';
import {
  FEELIX_THEMES, PAYMENT_METHOD, REGIONS, SUBSCRIPTION_TYPE
} from '../../constants';
import { SessionPropertiesType, SessionActionsType } from './sessionType';

const initialState: SessionPropertiesType = {
  pc: '',
  region: REGIONS.AUSTRALIA,
  promoCode: undefined,
  promotionEndDate: undefined,
  promotionTermsLink: undefined,
  productId: '',
  productName: '',
  productFreePeriod: -1,
  phoneNumber: {
    salesPhoneNumber: '',
    customerServicePhoneNumber: '',
    supportPhoneNumber: ''
  },
  checkoutId: (Date.now() + Math.random()).toString().replace(/\./g, '-'),
  isInitialised: false,
  subscriptionType: SUBSCRIPTION_TYPE.TRIAL,
  hasPurchaseSubmitted: false,
  contactArchieRowId: '',
  productInstanceId: '',
  serialNumber: '',
  orderNumber: '',
  isNewUser: false,
  paymentMethod: PAYMENT_METHOD.CREDIT,
  pCustomerId: '',
  isAnnualPrice: false,
  isAnnualRoute: false,
  theme: FEELIX_THEMES.MODERN,
  emailChecked: false
};

const sessionReducer =
  (state: SessionPropertiesType, action: Partial<SessionPropertiesType>):
  SessionPropertiesType => ({ ...state, ...action });

const getSessionActions = (setState: Dispatch<Partial<SessionPropertiesType>>): SessionActionsType => ({
  setProductName: (productName: string) => setState({ productName }),
  setPromoCode: (promoCode: string | undefined) => setState({ promoCode }),
  setPromotionEndDate: (promotionEndDate: string | undefined) => setState({ promotionEndDate }),
  setPromotionTermsLink: (promotionTermsLink: string | undefined) => setState({ promotionTermsLink }),
  setProductFreePeriod: (productFreePeriod: number) => setState({ productFreePeriod }),
  setHasPurchaseSubmitted: (hasPurchaseSubmitted: boolean) => setState({ hasPurchaseSubmitted }),
  setProductInstanceId: (productInstanceId: string) => setState({ productInstanceId }),
  setContactArchieRowId: (contactArchieRowId: string) => setState({ contactArchieRowId }),
  setSerialNumber: (serialNumber: string) => setState({ serialNumber }),
  setIsNewUser: (isNewUser: boolean) => setState({ isNewUser }),
  setOrderNumber: (orderNumber: string) => setState({ orderNumber }),
  setPaymentMethod: (paymentMethod: PAYMENT_METHOD) => setState({ paymentMethod }),
  setIsAnnualPrice: (isAnnualPrice: boolean) => setState({ isAnnualPrice }),
  setIsAnnualRoute: (isAnnualRoute: boolean) => setState({ isAnnualRoute }),
  setEmailChecked: (emailChecked: boolean) => setState({ emailChecked })
});
export const useSessionReducer = ():
[
  SessionPropertiesType,
  Dispatch<Partial<SessionPropertiesType>>,
  SessionActionsType
] => {
  const [state, setState] = useReducer(sessionReducer, initialState);
  const sessionActions = useMemo(() => getSessionActions(setState), [setState]);
  return [state, setState, sessionActions];
};
