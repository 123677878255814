import React, { useCallback, useEffect, useState } from 'react';
import {
  Spinner
} from '@myob/myob-widgets';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { Redirect } from 'react-router-dom';
import { useAddPaymentInfoEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { useGetEcommercePrice } from 'hooks/GTMAnalytics/useGTMAnalyticsHelper';
import { useSegmentContext } from 'contexts/segmentProvider/SegmentProvider';
import { trackEvent, useAddPaymentInfoEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { PaymentForm } from 'components/paymentForm/PaymentForm';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useRecaptcha } from 'hooks/useRecaptcha/useRecaptcha';
import { DirectDebitForm } from './directDebitForm/directDebitForm';
import { CreditCardForm } from './credtiCardForm/creditCardForm';
import { CreditCardDetail, DirectDebitDetail } from './types';
import {
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  GTM_ANALYTICS_CONSTANTS,
  PAYMENT_METHOD,
  PROMOTION_CODE,
  STEP_PATH
} from '../../constants';
import '../../app/App.scss';
import './PaymentPage.scss';

export const PaymentPage: React.FC = () => {
  const {
    region,
    promoCode,
    pCustomerId
  } = useSessionContext();

  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  const initialCardDetail = {
    cardType: '',
    cvv: '',
    expiryMonth: '',
    expiryYear: '',
    maskedNumber: '',
    nameOnCard: '',
    number: ''
  };

  const [creditCardDetail, setCreditCardDetail] = useState<CreditCardDetail>(initialCardDetail);

  const initialDirectDebitDetail: DirectDebitDetail = {
    accountName: '',
    authorised: false,
    bsb: '',
    accountNumber: '',
    bank: '',
    branch: '',
    account: '',
    suffix: ''
  };
  const [directDebitDetail, setDirectDebitDetail] = useState(initialDirectDebitDetail);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT);

  const history = useHistory();
  const {
    hasPurchaseSubmitted,
    setPaymentMethod: setPaymentMethodInContext,
    isAnnualPrice,
    isAnnualRoute
  } = useSessionContext();
  const isSegmentLibraryReady = useSegmentContext();
  const { currency, totalPrice } = useGetEcommercePrice();

  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);
  const [submitting, setSubmitting] = useState(false);
  const { success, isLoading } = useRecaptcha({
    enabled: submitting,
    action: 'submit',
    identityId: ''
  });

  const doCheckout = () => {
    const confirmationPath = `/${region.toLowerCase()}` +
      `${isAnnualRoute ? STEP_PATH.SUBSCRIBE.CONFIRMATION_ANNUAL : STEP_PATH.SUBSCRIBE.CONFIRMATION}`;
    const promoCodesNeedToPassPCustomerId = [
      PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE,
      PROMOTION_CODE.CODE_ANNUAL_WESTPACBUSINESSACC_12MTH_FREE
    ];
    history.push(confirmationPath, {
      pCustomerId: promoCodesNeedToPassPCustomerId.includes(promoCode ?? '') ? pCustomerId : null,
      paymentProfile: {
        creditCardDetail,
        directDebitDetail,
        paymentMethod,
        isAnnualPrice
      }
    });
  };
  useEffect(() => {
    if (success) {
      doCheckout();
    }
  }, [success]);
  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT,
    userId,
    anonymousId,
    properties: {
      currency,
      value: totalPrice,
      coupon: promoCode,
      myobVisitorId
    }
  };
  useAddPaymentInfoEventGA4(isSegmentLibraryReady);
  useAddPaymentInfoEvent(GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT);
  useSendEventToSegment({
    shouldSendSegmentEvent: !!currency && isSegmentLibraryReady,
    trackParams
  });
  const trackPaymentSubmission = useCallback((isValidPaymentProfile: boolean) => {
    trackEvent(isValidPaymentProfile ? 'payment_profile_valid' : 'payment_profile_invalid', {
      payment_type: paymentMethod
    });
  }, [paymentMethod]);

  if (hasPurchaseSubmitted || (!isLoading && success === false)) {
    return <Redirect to={'/error'}/>;
  }

  const handleSubmit = (isValidPaymentProfile: boolean) => {
    trackPaymentSubmission(isValidPaymentProfile);
    if (isValidPaymentProfile) {
      setSubmitting(true);
    }
  };

  if (isAuth0Loading) {
    return <Spinner/>;
  }

  if (!isAuthenticated) {
    return <Redirect to='/error' />;
  }

  const handlePaymentOptionChange = (paymentMethodOption: PAYMENT_METHOD) => {
    setPaymentMethod(paymentMethodOption);
    setPaymentMethodInContext(paymentMethodOption);
  };
  const renderPaymentForm = () => (paymentMethod === PAYMENT_METHOD.CREDIT ?
    <CreditCardForm
      isPaymentProfileCreationLoading={isLoading}
      setCreditCardDetail={setCreditCardDetail}
      onPaymentProfileSubmit={handleSubmit}
    /> :
    <DirectDebitForm
      isPaymentProfileCreationLoading={isLoading}
      setDirectDebitDetail={setDirectDebitDetail}
      onPaymentProfileSubmit={handleSubmit}
      promoCode={promoCode}
    />);

  return (
    <PaymentForm
      paymentMethod={paymentMethod}
      handlePaymentOptionChange={handlePaymentOptionChange}
    >
      {renderPaymentForm()}
    </PaymentForm>
  );
};
