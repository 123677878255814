import { useEffect } from 'react';

import {
  pushAsEcommerceToDataLayer,
  useGetAddPaymentInfoEvent,
  useGetPurchaseEvent,
  useGetBeginCheckoutEvent,
  useGetAddShippingInfoEvent,
  useGetCheckoutSignInEvent
} from './useGTMAnalyticsHelper';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { UseCommonEventProps } from './types';
import { pushToDataLayer } from '../Analytics/useAnalyticsHelper';

export const useCommonEvent = ({
  ecommerceEvent, shouldPushEvent
}:UseCommonEventProps):void => {
  useEffect(() => {
    if (shouldPushEvent && ecommerceEvent?.items?.length > 0) {
      pushAsEcommerceToDataLayer({ ...ecommerceEvent });
    }
  }, [JSON.stringify(ecommerceEvent), shouldPushEvent]);
};

export const useBeginCheckoutEventGA4 = (isReady: boolean): void => {
  const ecommerceEvent = useGetBeginCheckoutEvent();
  useCommonEvent({
    shouldPushEvent: isReady,
    ecommerceEvent
  });
};

export const useAddShippingInfoEventGA4 = (isNewUser: boolean): void => {
  const ecommerceEvent = useGetAddShippingInfoEvent(isNewUser);
  useCommonEvent({
    shouldPushEvent: true,
    ecommerceEvent
  });
};

export const useCheckoutSignInEventGA4 = (isNewUser: boolean, isReady: boolean): void => {
  const ecommerceEvent = useGetCheckoutSignInEvent(isNewUser);
  useCommonEvent({
    shouldPushEvent: isReady,
    ecommerceEvent
  });
};

export const useAddPaymentInfoEventGA4 = (isReady: boolean): void => {
  const ecommerceEvent = useGetAddPaymentInfoEvent();
  useCommonEvent({
    ecommerceEvent,
    shouldPushEvent: isReady
  });
};

export const usePurchaseEventGA4 = (): void => {
  const { orderNumber } = useSessionContext();
  const purchaseEvent = useGetPurchaseEvent();

  useEffect(() => {
    if (orderNumber &&
      purchaseEvent.items.length > 0) {
      pushAsEcommerceToDataLayer(purchaseEvent);
    }
  }, [
    JSON.stringify(purchaseEvent),
    orderNumber
  ]);
};

export const trackClickEvent = (event: string, attributes: Record<string, string>): void => {
  if (Object.keys(attributes).length) {
    pushToDataLayer({
      event,
      ...attributes
    });
  }
};

export const trackEvent = (event: string, attributes: Record<string, string>): void => {
  if (Object.keys(attributes).length) {
    pushToDataLayer({
      event,
      ...attributes
    });
  }
};

