import { FEELIX_THEMES, MOBILE_APP_CONFIGURED_PRODUCTS, STEP_PATH } from '../../../constants';
import { SessionApply, SessionPredicate } from '../sessionType';

export const hasMobileAppSession: SessionPredicate = (history) =>
  history.location.pathname.includes(STEP_PATH.MOBILE_APP.ROOT);

export const updateMobileAppSession: SessionApply = (history, setState) => {
  const { pathname } = history.location;
  const splitPathname = pathname.split('/');
  const productPath = splitPathname[2];
  if (productPath === MOBILE_APP_CONFIGURED_PRODUCTS.SOLO) {
    setState({ theme: FEELIX_THEMES.SOLO, isInitialised: true });
  }
};
