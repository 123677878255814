import { get } from 'lodash';
import qs from 'qs';
import {
  FEELIX_THEMES, REGIONS, SOLO_PRODUCT_IDS, STEP_PATH, SUBSCRIPTION_TYPE
} from '../../../constants';
import { SessionApply, SessionPredicate } from '../sessionType';

export const hasCheckoutSession: SessionPredicate = (history) => {
  const { search, pathname } = history.location;
  const splitPathname = pathname.split('/');
  const region = splitPathname[1];

  const hasValidRegion = region && Object.values(REGIONS).includes(region.toUpperCase());
  if (!hasValidRegion) return false;

  const queryString = qs.parse(search, { ignoreQueryPrefix: true });
  const productId = get(queryString, 'productId') as string;
  const activationCode = get(queryString, 'activationCode') as string;
  const hasRequiredParams = Boolean(productId) || Boolean(activationCode);
  if ([
    STEP_PATH.TRIAL.ROOT,
    STEP_PATH.SUBSCRIBE.ROOT,
    STEP_PATH.NEW_TRIAL.ROOT
  ].some((path) => pathname.includes(path)) &&
    hasRequiredParams) {
    return true;
  }

  return [
    STEP_PATH.SUBSCRIBE.PAYMENT,
    STEP_PATH.SUBSCRIBE.CONFIRMATION,
    STEP_PATH.TRIAL.CONFIRMATION,
    STEP_PATH.TRIAL.DUPLICATE,
    STEP_PATH.NEW_TRIAL.CONFIRMATION
  ].some((path) => pathname.includes(path));
};

export const updateCheckoutSession: SessionApply = (history, setState) => {
  const { search, pathname } = history.location;
  const queryString = qs.parse(search, { ignoreQueryPrefix: true });
  const productId = get(queryString, 'productId') as string;
  const activationCode = get(queryString, 'activationCode') as string;
  const pCustomerId = get(queryString, 'pcustid') as string;
  const pc = get(queryString, 'pc', '') as string;
  const splitPathname = pathname.split('/');
  const region = splitPathname[1];
  const isAnnualRoute = splitPathname.includes('annual');
  let subscriptionType = SUBSCRIPTION_TYPE.TRIAL;

  if (pathname.includes(STEP_PATH.SUBSCRIBE.ROOT)) {
    subscriptionType = SUBSCRIPTION_TYPE.SUBSCRIBE;
  } else if (pathname.includes(STEP_PATH.NEW_TRIAL.ROOT)) {
    subscriptionType = SUBSCRIPTION_TYPE.NEW_TRIAL;
  }
  setState({
    region: region.toUpperCase(),
    pCustomerId,
    isAnnualRoute,
    subscriptionType,
    isAnnualPrice: isAnnualRoute,
    productId,
    pc,
    theme: SOLO_PRODUCT_IDS.includes(productId) ? FEELIX_THEMES.SOLO : FEELIX_THEMES.MODERN,
    activationCode
  });
};
