import { Dispatch, useEffect } from 'react';
import { History } from 'history';
import { useGetProductData } from 'hooks/useGetProductData';
import { SUBSCRIPTION_TYPE } from '../../../constants';
import { SessionApply, SessionPredicate, SessionPropertiesType } from '../sessionType';
import { hasMobileAppSession, updateMobileAppSession } from './mobileAppSession';
import { hasCheckoutSession, updateCheckoutSession } from './checkoutSession';
import { errorSession } from './errorSession';

class SessionApplier {
  private initialised = false;

  private readonly history: History;

  private readonly setState: Dispatch<Partial<SessionPropertiesType>>;

  constructor(history: History, setState: Dispatch<Partial<SessionPropertiesType>>) {
    this.history = history;
    this.setState = setState;
  }

  isTruthy(predicate: SessionPredicate): boolean {
    return predicate instanceof Function ? predicate(this.history) : predicate;
  }

  when(predicate: SessionPredicate, apply: SessionApply): SessionApplier {
    if (this.isTruthy(predicate)) {
      apply(this.history, this.setState);
      this.initialised = true;
    }
    return this;
  }

  otherwise(apply: SessionApply): void {
    if (!this.initialised) apply(this.history, this.setState);
  }
}
export const initSession = (
  history: History,
  isInitialised: boolean,
  setState: Dispatch<Partial<SessionPropertiesType>>
): void => {
  const applier = new SessionApplier(history, setState);
  applier
    .when(isInitialised, () => setState({ isInitialised: true }))
    .when(hasMobileAppSession, updateMobileAppSession)
    .when(hasCheckoutSession, updateCheckoutSession)
    .otherwise(errorSession);
};

export const useUpdateProductDetailsState = (
  history: History,
  state: SessionPropertiesType,
  setState: Dispatch<Partial<SessionPropertiesType>>
): void => {
  const {
    productData, isError, isSuccess, isLoading
  } = useGetProductData({
    productId: state.productId,
    promoCode: state.pc,
    activationCode: state.activationCode
  });
  useEffect(() => {
    if (isLoading) return;
    if (isError) {
      setState({ isInitialised: true });
      history.push('/error');
    }
    if (isSuccess) {
      const key = state.subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE ? 'deal' : 'trialDeal';
      const verifiedPromoCode = productData.items[0][key]?.promoCode ?? '';
      setState({
        productId: productData.items[0].productId,
        promoCode: verifiedPromoCode,
        isAnnualPrice: !history.location.pathname.includes('annual') && verifiedPromoCode === state.pc ?
          productData.items[0].deal.recurringInterval === 'ANNUAL' :
          state.isAnnualPrice,
        isInitialised: true
      });
    }
  }, [
    productData,
    isError,
    isSuccess,
    isLoading,
    state.isInitialised,
    state.subscriptionType,
    history.location.pathname,
    state.pc]);
};
