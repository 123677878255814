import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect, useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  camelCase, get, mapKeys
} from 'lodash';
import { SessionContextType, PhoneNumbers } from './sessionType';

import {
  PHONE_NUMBERS
} from '../../constants';
import { useSessionReducer } from './sessionReducer';
import { initSession, useUpdateProductDetailsState } from './initSession/initSession';

export const SessionContext = createContext({} as SessionContextType);

export const useSessionContext = (): SessionContextType => useContext(SessionContext);

const getPhoneNumbers = (region: string): PhoneNumbers => {
  const phoneNumbersForRegion = get(PHONE_NUMBERS, region);
  return mapKeys(phoneNumbersForRegion, (value, key) => camelCase(key)) as {
    salesPhoneNumber: string;
    customerServicePhoneNumber: string;
    supportPhoneNumber: string;
  };
};

export const SessionProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const history = useHistory();
  const [state, setState, sessionActions] = useSessionReducer();

  useEffect(() => {
    initSession(history, state.isInitialised, setState);
    state.region && setState({ phoneNumber: getPhoneNumbers(state.region) });
  }, [history, state.region, state.isInitialised]);

  useUpdateProductDetailsState(history, state, setState);

  const value = useMemo(() => ({ ...state, ...sessionActions }), [
    JSON.stringify(state),
    sessionActions
  ]);
  return (
    <SessionContext.Provider value={value}>
      { state.isInitialised ? children : <div/> }
    </SessionContext.Provider>
  );
};
