import { useEffect } from 'react';
import { useContact } from 'hooks/useContactsCreation/useContact';
import { useAccount } from 'hooks/useAccountsCreation/useAccount';
import { usePaymentProfile } from 'hooks/usePaymentProfileCreation/usePaymentProfile';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { useFormCompleted, useFormSubmit } from 'hooks/Analytics/useAnalytics';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import axios from 'helpers/axios/axios';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { camelToSnake } from 'helpers/camelToSnake/camelToSnake';
import config from '../../Config';
import { ANALYTICS_ID, REQUEST_HEADERS } from '../../constants';
import { CheckoutResponse, CheckoutProps } from './types';

export type UseCheckoutType = {
  isLoading: boolean;
  isError: boolean;
  orderNumber: string;
  isSuccess: boolean;
};

const usePreValidate = () => {
  const {
    isLoading: isAuth0Loading, error: isAuth0Error
  } = useAuth0();

  const { isLoading: isProductDataLoading, isError: isProductDataError, productData } = useGetProduct();

  return {
    isLoading: isAuth0Loading || isProductDataLoading,
    isError: isProductDataError || !!isAuth0Error,
    productData
  };
};

const useCheckoutInternal =
  (
    {
      contact,
      account,
      paymentProfile
    }: {
      contact: any,
      account: any,
      paymentProfile: any;
    }
  ): UseCheckoutType => {
    const { accessToken } = useGetAccessToken();
    const options = {
      headers: {
        ...REQUEST_HEADERS,
        Authorization: `Bearer ${accessToken}`
      }
    };
    const {
      isLoading: validateLoading,
      isError: validateError,
      productData
    } = usePreValidate();
    useFormSubmit({ elementId: ANALYTICS_ID.SUBSCRIBE_FORM });
    const { setHasPurchaseSubmitted, activationCode } = useSessionContext();

    const {
      isLoading: isPurchasingLoading,
      isError: isPurchasingError,
      isSuccess,
      data
    } = useQuery(
      'checkout',
      () => axios.post<CheckoutResponse>(
        config.API_CHECKOUT_ENDPOINT,
        qs.stringify({
          account,
          contact,
          paymentProfile,
          deal_id: productData?.items[0]?.deal?.id,
          product_id: productData?.items[0].productId,
          region: productData?.region,
          ...(activationCode && { activationCode })
        }),
        options
      ),
      { enabled: (!validateLoading && !validateError && !!accessToken), retry: false }
    );

    useFormCompleted({ elementId: ANALYTICS_ID.SUBSCRIBE_FORM, isError: isPurchasingError });
    const { setSerialNumber, setProductInstanceId, setOrderNumber } = useSessionContext();
    useEffect(() => {
      if (isSuccess) {
        setSerialNumber(data?.data.serialNumber as string);
        setProductInstanceId(data?.data.productInstanceId as string);
        setOrderNumber((data?.data.purchaseId) as string);
      }
      if (isSuccess || isPurchasingError) {
        setHasPurchaseSubmitted(true);
      }
    }, [isSuccess, isPurchasingError]);

    const isLoading = validateLoading || isPurchasingLoading;
    const isError = validateError || isPurchasingError;
    const orderNumber = (data?.data.purchaseId) as string;
    return {
      isError,
      isLoading,
      orderNumber,
      isSuccess
    };
  };

export const useCheckout = (props: CheckoutProps): UseCheckoutType => {
  const contact = useContact();
  const account = useAccount();
  const paymentProfile = usePaymentProfile(props.paymentProfile);
  return useCheckoutInternal({
    contact: camelToSnake(contact),
    account,
    paymentProfile
  });
};

