import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useHistory } from 'react-router-dom';
import { useGetProductData } from 'hooks/useGetProductData';
import { UseGetProductDataType } from './types';

export const useGetProduct = (): UseGetProductDataType => {
  const history = useHistory();
  const {
    productId,
    promoCode,
    activationCode
  } = useSessionContext();
  const {
    productData, isLoading, isError, isSuccess
  } = useGetProductData({
    productId,
    promoCode,
    activationCode
  });
  if (isError) {
    history.push('/error');
  }
  return {
    productData, isLoading, isError, isSuccess
  };
};
