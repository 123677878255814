import React from 'react';
import { Text } from '@myob/myob-widgets';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { useFlowStep } from 'hooks/useFlowStep/useFlowStep';
import { FEELIX_THEMES, SUBSCRIPTION_TYPE } from '../../constants';
import defaultStyle from './SubscriptionHeading.module.scss';
import soloStyle from './SubscriptionHeading.solo.module.scss';

interface SubscriptionHeadingProps {
  theme: string
}

const getTitle =
  (subscriptionType: string, trialDealDuration: number, productFreePeriod: number, getLabels: any) => {
    if (subscriptionType === SUBSCRIPTION_TYPE.TRIAL) {
      return trialDealDuration === 1 ?
        getLabels(ELEMENTS.FREE_TRIAL_MESSAGE).replace('%s', '30-day') :
        getLabels(ELEMENTS.FREE_TRIAL_MESSAGE).replace('%s', `${trialDealDuration}-month`);
    }

    return (productFreePeriod && productFreePeriod > 0) ?
      getLabels(ELEMENTS.FREE_TRIAL_MESSAGE).replace('%s', productFreePeriod.toString()) :
      getLabels(ELEMENTS.SUBSCRIPTION_START_MESSAGE);
  };

export const SubscriptionHeading: React.FC<SubscriptionHeadingProps> =
  ({ theme }) => {
    const { productFreePeriod, subscriptionType } = useSessionContext();
    const flowStep = useFlowStep();
    const { productData, isLoading, isError } = useGetProduct();
    const { getLabels } = useContentContext();
    const isSoloTheme = theme === FEELIX_THEMES.SOLO;
    const style = isSoloTheme ? soloStyle : defaultStyle;

    if (isLoading || isError) return null;
    if (isSoloTheme && flowStep !== -1) return null;
    const trialDealDuration = productData?.items[0]?.trialDeal?.duration ?? 1;
    const title = getTitle(subscriptionType, trialDealDuration, productFreePeriod, getLabels);

    return (
      <Text
        className={`${style.subscriptionHeading}`}
        data-testid='subscription-heading'
        fontWeight='medium'
        frontSize='md'
      >
        {title}
      </Text>
    );
  };
