import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { SUBSCRIPTION_TYPE, ARL_PRODUCT_IDS } from '../../constants';
import { ConfirmationContentType } from './types';

const JB_HI_FI_PROMO_CODES = [
  'JBHIFI-12M-LITE',
  'JBHIFI-12M-PRO',
  'JBHIFI-12M-PAYROLL-ONLY'
];
export const useConfirmationContent = (): ConfirmationContentType => {
  const {
    isAnnualPrice, promoCode, subscriptionType, productId, region
  } = useSessionContext();
  return ({
    title: 'Thank you!',
    header: 'The first payment may be invoiced on a pro rata basis depending on the day of the month you sign up.',
    body: 'MYOB issues invoices on the 1st of each month and also offers 7 day payment terms, ' +
      'this means that you\'ll receive your invoice on the 1st, and any payment will be debited ' +
      'from your nominated bank account or credit card 7 days later.',
    footer: 'Your subscription auto-renews until it is cancelled. You can cancel at any ' +
      'time within the \'Manage My Product\' section of \'My Account\'.',
    button: 'progress',
    ...(isAnnualPrice && {
      header: 'The first payment may be invoiced on a pro rata basis depending on when you sign up. ' +
        'Payments will be debited from your nominated bank account or credit card.',
      body: 'Your subscription will auto-renew until it is cancelled. ' +
        'You can cancel by visiting the \'Manage My Product\' section of \'My Account\'.',
      footer: 'For further billing or account support, visit ',
      link: region === 'AU' ? 'https://www.myob.com/au/support' : 'https://www.myob.com/nz/support'
    }),
    ...(promoCode && JB_HI_FI_PROMO_CODES.includes(promoCode) && {
      header: 'You will receive a confirmation email with your MYOB Serial Number shortly.',
      body: undefined,
      footer: 'Your subscription auto-renews until it is cancelled. ' +
        'You can cancel within the \'Manage My Product\' section of \'My Account\'.'
    }),
    ...(ARL_PRODUCT_IDS.includes(productId) && {
      title: 'You\'re almost there!',
      header: 'MYOB issues invoices on the 1st of each month and any payment will be debited from your ' +
        'nominated bank account or credit card on the same day. Billing will auto-renew each month.',
      body: 'The first payment may be invoiced on a pro rata basis depending on the day you sign up.',
      nextSteps: {
        numbered: true,
        items: [
          'Download and install MYOB AccountRight',
          'Create your account (check your email for your invitation)',
          'Set up your file to start working on the desktop and in the browser' +
          ' (instructions with your serial number will be emailed to you)'
        ]
      },
      footer: 'Check your email for tips and tricks to getting started with MYOB AccountRight.',
      button: {
        text: 'Download MYOB AccountRight',
        link: 'https://download.myob.com/arl/Current/MYOB_AccountRight.exe'
      },
      showProgressButton: false
    }),
    ...(ARL_PRODUCT_IDS.includes(productId) && isAnnualPrice && {
      header: 'Your first payment may be invoiced pro rata depending on when you sign up. ' +
        'All payments will be debited from your nominated bank account or credit card. ' +
        'Your subscription will auto-renew annually until cancelled.',
      body: undefined,
      nextSteps: {
        numbered: true,
        items: [
          'Download and install MYOB AccountRight',
          'Check your email for an invitation to create your account. ' +
          'Instructions with your serial number will be sent to your email.',
          'Set up your file to start working on the desktop and in the browser'
        ]
      },
      footer: 'For further billing or account support, visit '
    }),
    ...(subscriptionType === SUBSCRIPTION_TYPE.NEW_TRIAL && {
      title: 'What happens next?'
    })
  });
};
