import React, {
  ReactNode,
  createContext,
  useContext,
  useCallback
} from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useContent } from 'hooks/useContent/useContent';
import { Redirect } from 'react-router-dom';
import { LoadingOverlay } from 'components/loading/LoadingOverlay';
import { getLabel } from './labels';
import { STEP_PATH } from '../../constants';
import { ContentType } from './types';

const ContentContext = createContext({} as ContentType);
export const useContentContext = (): ContentType => useContext(ContentContext);

const constructQueries = (productId: string, region: string, promotionCode?: string) => (
  {
    region,
    partials: [
      {
        model: 'products',
        properties: ['productId', 'fullName'],
        filters: {
          productId: [Number(productId) || 0]
        }
      },
      ...(promotionCode ? [{
        model: 'promotions',
        properties: ['terms'],
        filters: {
          promotionCode: [promotionCode]
        }
      }] : [])
    ].filter(Boolean)
  });

export const ContentProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const {
    productId,
    region,
    promoCode,
    theme,
    subscriptionType
  } = useSessionContext();

  const {
    isError,
    isLoading,
    data
  } = useContent(constructQueries(productId, region, promoCode));
  const getLabels = useCallback(
    (label: symbol) =>
      getLabel(label, subscriptionType, theme),
    [theme, subscriptionType]
  );

  if (isError) return <Redirect to={STEP_PATH.ERROR} />;
  const { products, promotions } = data || {};

  return (
    <ContentContext.Provider value={{ products, promotions, getLabels }}>
      <LoadingOverlay isLoading={isLoading}/>
      {children}
    </ContentContext.Provider>
  );
};
